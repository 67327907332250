import $ from 'jquery';
import reCaptchaValidate from './reCaptchaValidate';
import processEmailForm from './processEmailForm';

export default () => {

  $(function () {
    $('.event_registration__form').validate({
      onkeyup: false,
      onfocus: false,
      onsubmit: true,
      rules: {
        firstname: "required",
        lastname: "required",
        email: {
          required: true,
          email: true
        },
        state: "required"
      },
      messages: {
        firstname: "Your first name is required.",
        lastname: "Your last name is required.",
        email: {
          required: "Please enter your email",
          email: "Emails must contain @ and ."
        },
        state: "If US, please provide your state; otherwise select Canada/Non US."
      },
      submitHandler: function(form){

        var $copy = $('.event_registration__form__wrap'),
					$thankyou = $('.event_registration__thankyou'),
					$thankyou_message = $('.thankyou_message'),
					$error_message = $('.error_message'),
					$error = $('.event_registration__error'),
					$event_button = $('.event_registration__thankyou__button'),
					$thankyou_details = $('.thankyou_details');

        // Show the loading state
        $('.formfield__submit').parent().addClass('loading');

        function onSuccess(result) {
          switch (result.source) {
            case 'bigmarker':
              if (result.conference_url) {
                $event_button.attr("href", result.conference_url);
              } else {
                $thankyou_message.html(result.message);
                $event_button.remove();
              }
              break;
            case 'zoom':

              if (result.join_url) {
                //var a = $('<a></a>').attr("href",result.join_url).html(result.join_url);
                $thankyou_details.append('Please check your email for event details and your Join URL.  Click button to join');
                //$thankyou_details.append(a);
                $event_button.attr("href", result.join_url);
                $event_button.html('Join Now');
                $event_button.attr('style','margin-top:20px;');
              } else {
                $thankyou_message.html(result.message);
                $event_button.remove();
              }
              break;
            case 'self':
              $event_button.remove();
              break;
          }
          $copy.fadeOut('fast');
          $thankyou.fadeIn('slow');
        }

        function onError (result) {
          switch (result.source) {
            case 'zoom':
              if (result.message) {
                $error_message.html(result.message);
              }
              break;
          }
          $copy.fadeOut('fast');
          $error.fadeIn('slow');
        }

        reCaptchaValidate($(form).data('placement'))
        .then((result) => {

          var $form = $(form),
          $partner_email_optin = $('#partner_email_optin'),
          $taunton_email_optin = $('#taunton_email_optin'),
          $custom_data = $('.custom_data_collection_field'),
          $custom_data_ids = [],
          data = {
            'post_id' : $form.data('post_id'),
            'firstname' : $form.find('input[id=firstname]').val(),
            'lastname' : $form.find('input[id=lastname]').val(),
            'email' : $form.find('input[type=email]').val(),
            'state' : $form.find('select[id=state]').val(),
            'taunton_optin' : 0,
            'partner_optin' : 0
          },
          $optin = false;

          // Exlicitly set optin to 1 if checkbox is hidden but active or if the checkbox is visible and checked.
          if (($partner_email_optin.hasClass('hide') && !$partner_email_optin.hasClass('disabled')) || $form.find('#partner_newsletter_optin').prop('checked')) {
            data['partner_optin'] = 1;
          }

          // Exlicitly set optin to 1 if checkbox is hidden or if the checkbox is visible and checked.
          if ( $taunton_email_optin.hasClass('hide') || $form.find('#taunton_newsletter_optin').prop('checked')) {
            data['taunton_optin'] = 1;
            $optin = true;
          }

          // If Taunton optin checked; send email to Experian.
          if ($optin) {
            processEmailForm(form);
          }

          $custom_data.each(function() {
            var $data_id = this.id;
            $custom_data_ids.push($data_id);
            data[$data_id] = $(this).val();
          });

          data['custom_data_collection_fields'] = $custom_data_ids.join( "," );

          //Register Event with Host company and save data to WP database
          data['action'] = 'taunton_event_registration';
					data['ajax_nonce'] = $form.data('reg_nonce');
          $.ajax({
						type: 'POST',
						url: '/wp/wp-admin/admin-ajax.php',
            dataType: 'json',
						data: data,
						success: function(result: any) {
							console.log("success");
              console.log(result);
              if (result.error) {
								onError(result);
							} else {
								onSuccess(result);
							}
						},
						error: function (result: any) {
							console.log("error");
              console.log(result);
              onError(result);
						}
					});

        })
        .catch((result) => {
          $(form).replaceWith('<p>Spammer Detected!</p>');
          console.log(result);
        })
      }
    });
  });
}
